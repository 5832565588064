import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "placeholder-text"
    }}>{`Placeholder Text`}</h1>
    <p>{`Sometimes, we can use the space in an empty text box or field to show users what they should write
there. This may be referred to as placeholder text or hint text. For example, in a Search text box,
we might put “Search employees.” Write this text in sentence case (first letter of the phrase
capitalized, no period) and keep it as short as possible.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Search candidates`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Search Candidates`}</li>
      </ul>
    </Dont>
    <p>{`When placeholder text is used in a prompt or text box next to a field label, make sure the field
label text and the placeholder text are either both singular or both plural.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Field label: File Type`}<br parentName="li"></br>
          {`Placeholder text: Choose one or more`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Field label: Plan Type`}<br parentName="li"></br>
          {`Placeholder text: Choose some options`}</li>
      </ul>
    </Dont>
    <p>{`See also: `}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/instructional-text"
      }}>{`Instructional Text`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      